import React, { useEffect, useState, useContext, Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import Layout from "../components/layout"
import { Section } from "../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

export const AmpController = ({ location }) => {
  
  const getParameterValue = ({url, paramName}) => {
    
    if (url) {
      const queryString = url.split("?")[1]
      const queryParams = queryString.split("&")
    
      for (let i = 0; i < queryParams.length; i++) {
        const pair = queryParams[i].split("=")
        
        if (pair[0] === paramName) {
          return decodeURIComponent(pair[1])
        }
      }
    }
    
    return null
  }

  useEffect(() => {
    
    const getParameterValue = (paramName) => {
      const currentUrl = location?.href;
    
      if (currentUrl) {
        const queryString = currentUrl.split("?")[1];
    
        if (queryString) {
          const queryParams = queryString.split("&");
    
          for (let i = 0; i < queryParams.length; i++) {
            const pair = queryParams[i].split("=");
    
            if (pair[0] === paramName) {
              return decodeURIComponent(pair[1]);
            }
          }
        }
      }
      return null;
    }

    const nameParam = getParameterValue('name')
    const emailParam = getParameterValue('email')
    const phoneParam = getParameterValue('phoneNumber')

    console.log("phone_LOGGER-1 === ", phoneParam)

    if (phoneParam) {
      console.log("phone_LOGGER === ", phoneParam)
    } else {
      window.location.href= "/"
    }

  }, [])

  return(
    <Layout 
      location={location}
    >
      <Section 
        className="text-center"
      >
        <h1>This page can not access the get method</h1>
      </Section>
    </Layout>
  )
}
export default AmpController